import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ArrayUtils, NavigationSandbox, StringUtils } from '@smooved/core';
import { noop, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TableDef } from '../../table';
import { UiSize } from '../../ui.enums';
import { UiSandbox } from '../../ui.sandbox';
import { ClosableMobileModalComponent } from '../components/closable-modal/closable-mobile-modal.component';
import { ClosableModalComponent } from '../components/closable-modal/closable-modal.component';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { HubspotModal, HubspotModalConfig } from '../components/hubspot-modal/hubspot-modal.component';
import { ImageModalComponent } from '../components/image-modal/image-modal.component';
import { TableModalComponent } from '../components/table-modal/table-modal.component';
import { TextModalComponent } from '../components/text-modal/text-modal.component';
import { ModalClosingData } from '../interfaces/modal-closing-data.interface';
import { ClosableModalConfig, ConfirmModalConfig } from '../interfaces/modal-config.interface';
import { defaultModalConfig } from '../modal.constants';

@Injectable()
export class ModalSandbox {
    constructor(
        private readonly dialog: MatDialog,
        private readonly uiSandbox: UiSandbox,
        private readonly navigationSandbox: NavigationSandbox,
        private readonly translateService: TranslateService
    ) {}

    public openInfoModal<T, U>(component: ComponentType<T>, data?: U, id: string = ''): MatDialogRef<T> {
        return this.dialog.open(component, {
            data,
            panelClass: '__info-modal',
            id,
        });
    }

    public showTextModal(text: string): void {
        this.openInfoModal(TextModalComponent, text);
    }

    public showImageModal(image: string): void {
        this.openInfoModal(ImageModalComponent, image);
    }

    public openHubspotModal(config: HubspotModalConfig, header: string, subheader?: string, size = UiSize.Md): void {
        this.openClosableModal({
            component: HubspotModal,
            config: {
                header,
                subheader,
                size,
                input: {
                    config,
                },
            },
        });
    }

    public showTableModal<T, U>(tableDef: TableDef<T>, items: U[], size = UiSize.Lg): void {
        this.openClosableModal({
            component: TableModalComponent,
            config: {
                size,
                input: {
                    tableDef,
                    items,
                },
            },
        });
    }

    public openMobileModal<T>(
        component: ComponentType<T>,
        config: MatDialogConfig = {},
        id: string = '',
        fullScreenOnMobile = true
    ): MatDialogRef<T> {
        const panelClasses = this.panelClassToArray(config.panelClass);
        if (fullScreenOnMobile) panelClasses.push('__mobile-modal-full-screen');

        return this.dialog.open(component, {
            ...config,
            panelClass: ['__mobile-modal', ...panelClasses],
            id,
        });
    }

    public openConfirmModal(
        configForMobile: ConfirmModalConfig,
        callbackForMobile: (result: any) => void = noop,
        configForTabletPortraitUp?: ConfirmModalConfig,
        callbackForTabletPortraitUp?: (result: any) => void
    ): MatDialogRef<ModalClosingData, boolean> {
        const defaultConfirmModalConfig: MatDialogConfig = {
            panelClass: '__modal-confirm',
        };

        const hasMobileConfig = !StringUtils.isString(configForMobile);
        const hasTabletPortraitUpConfig = !StringUtils.isString(configForMobile);

        const onSetupForMobile = hasMobileConfig ? this.onSetupConfirmModal<string, ConfirmModalComponent>(configForMobile) : noop;
        const onSetupForTabletPortraitUp = hasTabletPortraitUpConfig
            ? this.onSetupConfirmModal<string, ConfirmModalComponent>(configForTabletPortraitUp || configForMobile)
            : noop;

        return this.openDialog<ConfirmModalComponent, ConfirmModalComponent, boolean>({
            componentForMobile: ConfirmModalComponent,
            configForMobile: { ...defaultConfirmModalConfig, ...configForMobile },
            callbackForMobile,
            onSetupForMobile,
            componentForTabletPortraitUp: ConfirmModalComponent,
            configForTabletPortraitUp: { ...defaultConfirmModalConfig, ...(configForTabletPortraitUp || configForMobile) },
            callbackForTabletPortraitUp: callbackForTabletPortraitUp || callbackForMobile,
            fullScreenForMobile: false,
            onSetupForTabletPortraitUp,
        });
    }

    public openModal<T, U>(
        componentForMobile: ComponentType<T>,
        configForMobile: MatDialogConfig,
        callbackForMobile: (result: any) => void,
        componentForTabletPortraitUp: ComponentType<U>,
        configForTabletPortraitUp: MatDialogConfig,
        callbackForTabletPortraitUp: (result: any) => void,
        closeOnNavigate: boolean = true,
        onSetupForMobile = (_dialogRef: MatDialogRef<any>) => {
            /** */
        },
        onSetupForTabletPortraitUp = (_dialogRef: MatDialogRef<any>) => {
            /** */
        },
        fullScreenForMobile?: boolean
    ): MatDialogRef<ModalClosingData, any> {
        const callbacksForMobile = [callbackForMobile];
        const callbacksForTabletPortraitUp = [callbackForTabletPortraitUp];
        const modalDestroy$ = new Subject<boolean>();

        let modalRef: MatDialogRef<ModalClosingData>;

        // Close dialog when navigated
        if (closeOnNavigate) {
            this.navigationSandbox.routeNavigationEndEvent$.pipe(takeUntil(modalDestroy$)).subscribe(() => {
                modalRef?.close({ navigatedAway: true });
            });
        }

        const setupModal = (
            modalRef: MatDialogRef<any>,
            callbacks: ((result: any) => void)[],
            onSetup: (_dialogRef: MatDialogRef<any>) => void
        ): void => {
            modalRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((result) => {
                    callbacks.forEach((cb) => !!cb && cb(result));
                    modalDestroy$.next(true);
                    modalDestroy$.complete();
                });
            onSetup(modalRef);
        };

        if (this.uiSandbox.isTabletPortraitUp && componentForTabletPortraitUp) {
            modalRef = this.dialog.open(componentForTabletPortraitUp, configForTabletPortraitUp);
            setupModal(modalRef, callbacksForTabletPortraitUp, onSetupForTabletPortraitUp);
        } else if (componentForMobile) {
            modalRef = this.openMobileModal(componentForMobile, configForMobile, undefined, fullScreenForMobile);
            setupModal(modalRef, callbacksForMobile, onSetupForMobile);
        }

        return modalRef;
    }

    public closeAll(): void {
        this.dialog.closeAll();
    }

    public openDialog<T, U, R>(config: {
        componentForMobile: ComponentType<T>;
        configForMobile?: MatDialogConfig;
        callbackForMobile?: (result?: R) => void;
        fullScreenForMobile?: boolean;
        componentForTabletPortraitUp: ComponentType<U>;
        configForTabletPortraitUp?: MatDialogConfig;
        callbackForTabletPortraitUp?: (result?: R) => void;
        closeOnNavigate?: boolean;
        onSetupForMobile?: (dialogRef: MatDialogRef<T, U>) => void;
        onSetupForTabletPortraitUp?: (dialogRef: MatDialogRef<T, U>) => void;
    }): MatDialogRef<ModalClosingData, any> {
        return this.openModal(
            config.componentForMobile,
            config.configForMobile || {},
            config.callbackForMobile || noop,
            config.componentForTabletPortraitUp,
            config.configForTabletPortraitUp || {},
            config.callbackForTabletPortraitUp || noop,
            config.closeOnNavigate,
            config.onSetupForMobile,
            config.onSetupForTabletPortraitUp,
            config.fullScreenForMobile
        );
    }

    public openClosableModal<T>(config: {
        component: ComponentType<unknown>;
        config?: ClosableModalConfig<T>;
        afterClosed?: (result: unknown) => void;
        componentForTabletPortraitUp?: ComponentType<unknown>;
        configForTabletPortraitUp?: ClosableModalConfig<T>;
        afterClosedForTabletPortraitUp?: (result: unknown) => void;
        fullScreenForMobile?: boolean;
        size?: UiSize;
    }): MatDialogRef<ModalClosingData, any> {
        const confTabletPortraitUp = config.configForTabletPortraitUp || config.config;
        const cmpntTabletPortraitUp = config.componentForTabletPortraitUp || config.component;
        const onSetupForMobile = this.onSetupClosableMobileModal<T, ClosableMobileModalComponent>(config.component, config.config);
        const onSetupForTabletPortraitUp = this.onSetupClosableModal<T, ClosableModalComponent>(
            cmpntTabletPortraitUp,
            confTabletPortraitUp
        );

        const modalSizeClass = config.config?.size ? `__modal-${config.config.size}` : null;

        const classList = ['__modal', '__modal-closable', config.config?.panelClass ?? defaultModalConfig.panelClass];
        if (config.config?.removePadding) classList.push('__modal--no-padding');
        if (config.config?.hasActionButtons) classList.push('__modal--action-buttons');
        const panelClass = this.parsePanelClass(classList);
        const panelClassPortraitUp = this.parsePanelClass([panelClass, modalSizeClass]);

        const dialogConfigForMobile = { ...defaultModalConfig, ...config.config, panelClass };
        const dialogConfigTabletPortraitUp = {
            ...defaultModalConfig,
            ...confTabletPortraitUp,
            ...config.config,
            panelClass: panelClassPortraitUp,
        };

        return this.openDialog({
            componentForMobile: ClosableMobileModalComponent,
            configForMobile: dialogConfigForMobile,
            callbackForMobile: config.afterClosed,
            componentForTabletPortraitUp: ClosableModalComponent,
            configForTabletPortraitUp: dialogConfigTabletPortraitUp,
            callbackForTabletPortraitUp: config.afterClosedForTabletPortraitUp || config.afterClosed,
            closeOnNavigate: true,
            onSetupForMobile,
            onSetupForTabletPortraitUp,
            fullScreenForMobile: config.fullScreenForMobile,
        });
    }

    private onSetupClosableModal<T, U extends ClosableModalComponent>(component: ComponentType<unknown>, config?: ClosableModalConfig<T>) {
        return (dialogRef: MatDialogRef<U, U>): void => {
            dialogRef.componentInstance.component = component;
            dialogRef.componentInstance.header = config?.header;
            dialogRef.componentInstance.headerClass = config?.headerClass;
            dialogRef.componentInstance.subheader = config?.subheader;
            dialogRef.componentInstance.maxWidth = config?.maxWidth || config?.width;
            dialogRef.componentInstance.input = config?.input;
            dialogRef.componentInstance.output = config?.output;
            dialogRef.componentInstance.delete = config?.onDelete;
            dialogRef.componentInstance.hideCloseButton = config?.hideCloseButton;
        };
    }

    private onSetupClosableMobileModal<T, U extends ClosableMobileModalComponent>(
        component: ComponentType<unknown>,
        config?: ClosableModalConfig<T>
    ) {
        return (dialogRef: MatDialogRef<U, U>): void => {
            dialogRef.componentInstance.component = component;
            dialogRef.componentInstance.header = config?.header;
            dialogRef.componentInstance.subheader = config?.subheader;
            dialogRef.componentInstance.useBackNavigation = config?.useMobileBackNavigation ?? true;
            dialogRef.componentInstance.input = config?.input;
            dialogRef.componentInstance.output = config?.output;
            dialogRef.componentInstance.delete = config?.onDelete;
            dialogRef.componentInstance.hideCloseButton = config?.hideCloseButton;
        };
    }

    private onSetupConfirmModal<T, U extends ConfirmModalComponent>(config?: ConfirmModalConfig) {
        return (dialogRef: MatDialogRef<U, U>): void => {
            dialogRef.componentInstance.header = config?.header;
            dialogRef.componentInstance.subheader = config?.subheader;
            dialogRef.componentInstance.input = config?.input;
            dialogRef.componentInstance.output = config?.output;
            dialogRef.componentInstance.question = config?.question;
            dialogRef.componentInstance.submitLabel = config?.submitLabel ?? dialogRef.componentInstance.submitLabel;
            dialogRef.componentInstance.cancelLabel = config?.cancelLabel ?? dialogRef.componentInstance.cancelLabel;
        };
    }

    private panelClassToArray(panelClass: string | string[]): string[] {
        return (Array.isArray(panelClass) ? panelClass : panelClass?.split(' ')) || [];
    }

    public parsePanelClass(lists: (string | string[])[]): string[] {
        return lists
            .map((list) => ArrayUtils.toArray(list))
            .reduce((classes, list): string[] => {
                return [...classes, ...this.panelClassToArray(list)];
            }, []);
    }

    public onDeleteConfirm(config?: { label?: string; afterClosed?: () => void }): void {
        const data = this.translateService.instant(config?.label || 'DELETE.CONFIRM') as string;

        const onConfirm = (confirmed: boolean): void => {
            if (!confirmed) return;
            config?.afterClosed?.();
        };

        this.openConfirmModal({ data }, onConfirm, { data }, onConfirm);
    }
}
